import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import MediaQuery from "react-responsive"

import { ReactComponent as BulbWhite } from "../images/bulb-white.svg"

const PcFooter = ({ Icons }) => (
  <footer>
    <Link to="/" style={{ color: "inherit" }}>
      <div className="footer-title">
        <BulbWhite width="27.8" height="36.32" />
        <h1>近未来体験2021</h1>
      </div>
    </Link>
    <div className="sitemap">
      <div>
        <h2>配信を見る</h2>
        <div className="sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#ec69c6" }}>
              ››
            </span>
            {"　"}
            <Link to="/live"> 配信ページ</Link>
          </h3>
          {/* <h3>
            <span className="sitemap-link-arrow" style={{ color: "#ec69c6" }}>
              ››
            </span>
            {"　"}
            <Link to="/live#timetable"> タイムテーブル</Link>
          </h3> */}
        </div>
      </div>
      <div>
        <h2>つくってみる</h2>
        <div className="sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#56d665" }}>
              ››
            </span>
            {"　"}
            <Link to="/craft"> 電子工作教室</Link>
          </h3>
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#56d665" }}>
              ››
            </span>
            {"　"}
            <Link to="/programming"> プログラミング教室</Link>
          </h3>
        </div>
      </div>
      <div>
        <h2>電気系に親しんでみる</h2>
        <div className="sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#0095f2" }}>
              ››
            </span>
            {"　"}
            <Link to="/nazotoki"> 電気系謎解き</Link>
          </h3>
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#0095f2" }}>
              ››
            </span>
            {"　"}
            <Link to="/game"> ゲーム（AI・論理回路）</Link>
          </h3>
        </div>
      </div>
      <div>
        <h2>EEICをのぞいてみる</h2>
        <div className="sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#d1d656" }}>
              ››
            </span>
            {"　"}
            <Link to="/works"> 学生制作物展示</Link>
          </h3>
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#d1d656" }}>
              ››
            </span>
            {"　"}
            <Link to="/lab"> 研究室紹介</Link>
          </h3>
        </div>
      </div>
      <div>
        <div className="footer-logo">
          <a
            href="https://twitter.com/eeic_studentadv"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={Icons.twitter.childImageSharp.fixed} alt="Twitter" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCWPrxA-tgo7ePClMeZhMn0g"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={Icons.youtube.childImageSharp.fixed} alt="YouTube" />
          </a>
        </div>
        <div className="sitemap-link">
          <h3>
            <span className="sitemap-link-arrow">››</span>
            {"　"}投票は
            <a href="https://gogatsusai.jp/94/mfa/vote?id=329"> こちら</a>
          </h3>
        </div>
        <Img fixed={Icons.logo.childImageSharp.fixed} alt="EEIC 近未来体験2021" />
      </div>
    </div>
    <div className="copy">
      <p>&copy;2021　東京大学工学部　電気電子・電子情報工学科　学生展示</p>
    </div>
  </footer>
)

const SmFooter = ({ Icons }) => (
  <footer className="sm-footer">
    <div className="sm-footer-top">
      <h1>近未来体験2021</h1>
      <div className="sm-footer-social">
        <a
          href="https://twitter.com/eeic_studentadv"
          target="_blank"
          rel="noreferrer"
        >
          <Img fixed={Icons.sm_twitter.childImageSharp.fixed} alt="Twitter" />
        </a>
        <a href="#" target="_blank">
          <Img fixed={Icons.sm_youtube.childImageSharp.fixed} alt="YouTube" />
        </a>
      </div>
    </div>
    <div className="sm-sitemap-link sm-sitemap-home">
      <h3>
        <span className="sitemap-link-arrow">››</span>

        <a href="/"> ホーム</a>
      </h3>
    </div>
    {/* <div className="sm-footer-sitemap-container"> */}
    <div className="sm-footer-sitemap">
      <div className="sm-sitemap-block">
        <h2>配信を見る</h2>
        <div className="sm-sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#ec69c6" }}>
              ››
            </span>

            <Link to="/live"> 配信ページ</Link>
          </h3>
        </div>
      </div>
      <div className="sm-sitemap-block">
        <h2>つくってみる</h2>
        <div className="sm-sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#56d665" }}>
              ››
            </span>

            <Link to="/craft"> 電子工作教室</Link>
          </h3>
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#56d665" }}>
              ››
            </span>

            <Link to="/programming"> プログラミング教室</Link>
          </h3>
        </div>
      </div>
      <div className="sm-sitemap-block">
        <h2>あそんでみる</h2>
        <div className="sm-sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#0095f2" }}>
              ››
            </span>

            <Link to="/nazotoki"> 電気系謎解き</Link>
          </h3>
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#0095f2" }}>
              ››
            </span>

            <Link to="/game"> ゲーム（AI・論理回路）</Link>
          </h3>
        </div>
      </div>
      <div className="sm-sitemap-block">
        <h2>EEICをのぞいてみる</h2>
        <div className="sm-sitemap-link">
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#d1d656" }}>
              ››
            </span>

            <Link to="/works"> 学生制作物展示</Link>
          </h3>
          <h3>
            <span className="sitemap-link-arrow" style={{ color: "#d1d656" }}>
              ››
            </span>

            <Link to="/lab"> 研究室紹介</Link>
          </h3>
        </div>
      </div>
    </div>
    {/* </div> */}
    <div className="sm-footer-logo">
      <Img fixed={Icons.logo.childImageSharp.fixed} alt="EEIC 近未来体験2021" />
    </div>
    <div className="sm-copy">
      <p>&copy;2021　東京大学工学部</p>
      <p>電気電子・電子情報工学科　学生展示</p>
    </div>
  </footer>
)

const Footer = () => {
  const Icons = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 133) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        youtube: file(relativePath: { eq: "youtube.png" }) {
          childImageSharp {
            fixed(height: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        sm_youtube: file(relativePath: { eq: "youtube.png" }) {
          childImageSharp {
            fixed(height: 22) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        twitter: file(relativePath: { eq: "twitter.png" }) {
          childImageSharp {
            fixed(height: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        sm_twitter: file(relativePath: { eq: "twitter.png" }) {
          childImageSharp {
            fixed(height: 22) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <>
      <MediaQuery query="(max-width: 1100px)">
        <SmFooter Icons={Icons} />
      </MediaQuery>
      <MediaQuery query="(min-width: 1100px)">
        <PcFooter Icons={Icons} />
      </MediaQuery>
    </>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
