import PropTypes from "prop-types"
import React from "react"
import * as style from "./ProgrammingDetail.module.css"

const programmingDetail = ({data}) => {
  return (
    <div className={style.container}>
      <div className="common-detail-wrapper"> 
				<div className={style.title_holder}>
					<h1 className={style.title+" "+style.border}>{data.title}</h1>
				</div>
				<div className={style.container+" "+style.flex}> 
					<div className={style.container+" "+style.subcontent}>
						<img className={style.img} src={require("../../images/programming/" + data.img)} alt=""/>
					</div>
					<div className={style.container+" "+style.subcontent}> 
						<div className={style.py10}>
							<h2 className={style.border+" "+style.subtitle}>ABOUT</h2>
							<div>{data.about}</div> 
						</div> 
						<div className={style.py10}>
              <div style={{textAlign: 'center'}}> 
                <a href={data.url_howtoplay} target="_blank" rel="noreferrer">
				          <button className={style.button}>遊び方を見る</button>
                </a>
              </div>
              <div style={{textAlign: 'center'}}> 
                <a href={data.url_game} target="_blank" rel="noreferrer"><button className={style.button}>遊んでみる</button></a>
              </div>
              <div style={{textAlign: 'center'}}>*外部サイトにリンクします</div>
						</div> 
					</div>
				</div>
      </div>
    </div>
  )
}

programmingDetail.protoTypes = {
  data:PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
}

export default programmingDetail