import PropTypes from "prop-types"
import React, { useState } from "react"
import * as style from "./CraftDetail.module.css"
import PaperContent from "./paper"
import SensorContent from "./sensor"
import WirelessContent from "./wireless"

const CraftDetail = ({ data }) => {
  const [selected_kit, setKit] = useState("none")
  const clickFunc = id => {
    setKit(id)
    const nextDiv = document.getElementById("paper-content-wrap")
    const yOffset = nextDiv.getBoundingClientRect().top
    window.scrollBy(0, (yOffset-60))
  }
  return (
    <div className={style.container}>
      <div className="common-detail-wrapper">
        <div className={style.title_holder}>
          <h1 className={style.title + " " + style.border}>電子工作教室</h1>
        </div>
        <div className={style.container}>
          <h2>キット販売中！
            <a className={style.button} href="https://eeicstore.thebase.in/" target="_blank" rel="noreferrer">
              ご購入はこちらから
            </a>
          </h2>
          例年EEICでは、はんだ付けにより電子工作を体験できるキットを製作しています。
          <br></br>
          今年度はコロナ禍においてもこの活動を継続すべく、AgIC回路マーカーを使い、はんだ付けの不要なかんたん電子工作キットを3種類考案しました。
          <br />
          <br />
          <h3>キットの説明はこちら↓</h3>
        </div>
        <div className={style.container + " " + style.flex}>
          {data.map(craft => {
            return (
              <div className={style.card}>
                <img
                  className={style.img}
                  src={require("../../images/craft/" + craft.img)}
                  alt="craft"
                />
                <div
                  className={style.craft_title}
                  dangerouslySetInnerHTML={{
                    __html: craft.title,
                  }}
                ></div>
                <div
                  className={style.button}
                  onClick={() => clickFunc(craft.id)}
                >
                  ››詳細を見る
                </div>
              </div>
            )
          })}
        </div>
        <div id="paper-content-wrap">
          {selected_kit === "paper" && <PaperContent />}
          {selected_kit === "sensor" && <SensorContent />}
          {selected_kit === "wireless" && <WirelessContent />}
        </div>
        <div className={style.title_holder}>
          <h1 className={style.title + " " + style.border}>制作風景</h1>
        </div>
        <div className={style.movie_holder}>
          <iframe
            className={style.movie}
            src="https://www.youtube.com/embed/BQiEvyaZUXQ" 
            title="YouTube video player"
            frameborder="0"
            scrolling="no"
            allow="accelerometer; clipboard-write;encrypted-media;"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

CraftDetail.protoTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
}

export default CraftDetail
