import React, { useState } from "react"
import { graphql } from "gatsby"

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import HeroBack from "../components/hero_back"
import Section from "../components/section"
import TSUKUMO from "../images/TSUKUMO.svg"
import ProjectGroup from "../components/projects/ProjectGroup"
import LinkTiles from "../components/LinkTiles"

const IndexPage = ({ data }) => {
  const otherPartLinks = [
    {
      title: "工学部展示トップ",
      subTitle: "工学部合同企画",
      headImage: data.KougakubuImage,
      path: "https://sites.google.com/g.ecc.u-tokyo.ac.jp/foeonline2021/",
    },
    {
      title: "バーチャル東大",
      subTitle: "工学部合同企画",
      headImage: data.virtualUTImage,
      path: "https://vr.u-tokyo.ac.jp/virtualUT/engineeringsquare",
    },
    {
      title: "精密Lab",
      subTitle: "精密工学科",
      headImage: data.seimitsuImage,
      path: "https://www.pemayfes.t.u-tokyo.ac.jp/2021/",
    },
    {
      title: "Techno Factory",
      subTitle: "機械系二学科",
      headImage: data.kikaiImage,
      path: "https://ut-mech.com/",
    },
    {
      title: "宙の青さを知る",
      subTitle: "航空宇宙工学科",
      headImage: data.koukuuImage,
      path: "https://www.utaerospace21.net/",
    },
    {
      title: "化生系研究室に突撃してみた！！！",
      subTitle: "化生系三学科",
      headImage: data.kaseikeiImage,
      path: "https://t.co/HEuRa7gGpz?amp=1",
    },
    {
      title: "都市をつくる人は今",
      subTitle: "社会基盤学科",
      headImage: data.shakaikibanImage,
      path: "https://sites.google.com/view/utcivil/",
    },
  ]

  const [annouceOpen1, setAnnouceOpen1] = useState(false)
  const [annouceOpen2, setAnnouceOpen2] = useState(false)

  return (
    <Layout>
      <SEO
        keywords={[
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `電子情報機器学`,
          `電子工作`,
          `プログラミング`,
          `VTuber`,
          `バーチャルYouTuber`,
          `御城みつき`,
          `風里えれな`,
          `VR`,
          `AR`,
          `mitsuele_eeic`,
          `eeic_studentadv`,
        ]}
      />
      <HeroBack className="background-hero">
        <Hero className="index-hero">
          <div className="index-element">
            <div className="index-logo">
              {/* <BulbWhite className="whitebulb-logo" /> */}
            </div>
            <div className="index-description">
              <br />
              <h2 className="title-little">
                EEIC
                {` `}
                学科展示
              </h2>
              <br />
              <h2>近未来体験2021</h2>
              <br />
              <br className="middle-off" />
              <p>
                東京大学工学部・電気系2学科による展示です。
                <br />
                配信企画を筆頭に、
                <br class="mobile-on" />
                ものづくり体験や謎解き・ゲームなど
                <br class="mobile-on" />
                楽しめるコンテンツが 盛りだくさん。
              </p>
              <p>ぜひ、「近未来」を実感してください！</p>
            </div>
          </div>
          <p className="see-more">
            <span className="see-more-array1" />
            <span className="see-more-array2" />
            もっと見る
          </p>
        </Hero>
      </HeroBack>
      <Section id="latest">
        <h2>最新情報</h2>
        <div className="newscolumn">
          <div className="news important">
            <div className="newsdate">2021.9.20</div>
            <div classaName="newsdetail">『近未来体験2021』は第94回五月祭 オンライン部門賞を受賞しました！[下記]</div>
          </div>
          <div className="news">
            <div className="newsdate">2021.9.20</div>
            <div classaName="newsdetail">近未来体験のグッズを販売中！</div>
          </div>
          <div className="news">
            <div className="newsdate">2021.9.19</div>
            <div classaName="newsdetail">電子工作教室の制作手順動画を公開しました！</div>
          </div>
          <div className="news">
            <div className="newsdate">2021.9.19</div>
            <div classaName="newsdetail">配信のURL・スケジュールを公開しました！</div>
          </div>
          <div className="news important">
            <div className="newsdate">2021.9.10</div>
            <div classaName="newsdetail">五月祭(9/19,20開催)に本企画が出展します！</div>
          </div>
        </div>
        <div class="announcement">
          <div class="announcefold" onClick={() => {
                  setAnnouceOpen1(!annouceOpen1)
                }}
                style={{ cursor: "pointer" }}>
            <div>
              <h3>
                オンライン部門賞を受賞しました！<FontAwesomeIcon
                icon={annouceOpen1 ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
              </h3>
              {annouceOpen1 && (
                <p>
                  『近未来体験2021』は第94回五月祭 オンライン部門賞を受賞しました！(<a href="https://gogatsusai.jp/94/visitor/awards" target="_brank">こちら</a>)<br />
                  オンラインでの五月祭には様々な制約もありましたが、その一方でオンラインならではの企画を作ることができました。<br />
                  展示をお楽しみくださったみなさま、ありがとうございました！
                </p>
              )}
            </div>
          </div>
        </div>
      </Section>
      <section className="section-project">
        <h2>企画一覧</h2>
        <div className="project-groups">
          <ProjectGroup
            title="配信を見る"
            color="#FF00FF"
            projectParts={[
              {
                title: "配信企画",
                time: 15,
                introduction:
                  "EEICオリジナルバーチャルYouTuberみつえれが「近未来体験2021」をご紹介。\n9/19, 20 13:00から配信中！",
                headImage: data.liveImage,
                path: "/live",
              },
            ]}
          />
          <ProjectGroup
            title="つくってみる"
            color="#56D665"
            projectParts={[
              {
                title: "電子工作教室",
                time: 10,
                introduction:
                  "AgIC回路マーカーを使ったかんたん電子工作キットをご紹介！",
                headImage: data.craftImage,
                path: "/craft",
              },
              {
                title: "プログラミング教室",
                time: 10,
                introduction:
                  "ゲームを通してプログラミングを学ぼう！初心者から経験者まで誰でも大歓迎！",
                headImage: data.programmingImage,
                path: "/programming",
              },
            ]}
          />
        </div>
        <div className="project-groups">
          <ProjectGroup
            title="あそんでみる"
            color="#0095F2"
            projectParts={[
              {
                smallerTitle: "みつえれからの挑戦状",
                time: 10,
                introduction:
                  "みつきとえれなから謎解き挑戦状が届いたよ！電気に関する謎解きに挑戦してみよう！",
                headImage: data.nazotokiImage,
                path: "/nazotoki",
                spacing: 12,
              },
              {
                title: "ゲーム",
                smallerTitle: "(AI・論理回路)",
                time: 10,
                introduction: "ゲームを楽しむ。EEICに親しむ。",
                headImage: data.gameImage,
                path: "/game",
              },
            ]}
          />
          <ProjectGroup
            title="EEICをのぞいてみる"
            color="#D1D656"
            projectParts={[
              {
                title: "研究室紹介",
                time: 10,
                introduction:
                  "ナノ制御から太陽プラズマまで、電気の研究内容をご紹介！",
                headImage: data.topLabImage,
                path: "/lab",
              },
              {
                title: "学生制作物展示",
                time: 10,
                introduction:
                  "電気や情報に精通するEEICの学生が作成した、創意工夫ある展示物の数々をご覧ください",
                headImage: data.bdmImage,
                path: "/works",
              },
            ]}
          />
        </div>
      </section>
      <Section id="taidan">
        <h2>グッズ販売</h2>
        <p>
          みつえれのグッズを販売しています！
        </p>
        <a
          href="https://eeicstore.thebase.in/items/43680591"
          target="_blank"
          rel="noreferrer"
        >
          <button class="taidan-button">キーホルダー</button>
        </a>
        <a
          href="https://eeicstore.thebase.in/items/43905677"
          target="_blank"
          rel="noreferrer"
        >
          <button class="taidan-button">Tシャツ</button>
        </a>
      </Section>
      <Section id="taidan">
        <h2>情報系学科対談</h2>
        <p>
          同時期に企画を開く予定の情報系学科に所属する学生で対談を行いました。ぜひご一読ください！
        </p>
        <a
          href="https://note.com/kogakubu_godo/n/n1813af70510b"
          target="_blank"
          rel="noreferrer"
        >
          <button class="taidan-button">対談ページへ（note)</button>
        </a>
      </Section>
      <Section id="other-departments">
        <h2>他学科企画</h2>
        <div className="other-departments">
          <LinkTiles
            title="リンク集"
            color="#0095F2"
            LinkParts={otherPartLinks}
          ></LinkTiles>
        </div>
      </Section>

      <Section id="sponsor">
        <h2>協力企業様</h2>
        <span className="block-link" style={{ padding: `0 1rem 1px` }}>
          <div>
            <img src={TSUKUMO} alt="TSUKUMO" />
          </div>
          <p>
            パソコン・パソコンパーツの専門店ツクモです。
            <br className="mobile-off" />
            ツクモオリジナルPCの開発・販売のほか、各種VR機器を取り扱っております。
          </p>
        </span>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    pikyoImage: file(relativePath: { eq: "pikyo.jpg" }) {
      ...SectionHeadImage
    }
    workshopImage: file(relativePath: { eq: "workshop.jpg" }) {
      ...SectionHeadImage
    }
    exhibitionImage: file(relativePath: { eq: "exhibition.png" }) {
      ...SectionHeadImage
    }
    labImage: file(relativePath: { eq: "lab.jpg" }) {
      ...SectionHeadImage
    }
    craftImage: file(relativePath: { eq: "top/craft.jpeg" }) {
      childImageSharp {
        fixed(width: 217, height: 144) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    liveImage: file(relativePath: { eq: "top/live.png" }) {
      childImageSharp {
        fixed(width: 217, height: 122) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    programmingImage: file(relativePath: { eq: "top/programming.png" }) {
      childImageSharp {
        fixed(width: 217, height: 144) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    nazotokiImage: file(relativePath: { eq: "top/nazotoki.png" }) {
      childImageSharp {
        fixed(width: 217, height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gameImage: file(relativePath: { eq: "top/game.png" }) {
      childImageSharp {
        fixed(width: 217, height: 144) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    topLabImage: file(relativePath: { eq: "top/lab.png" }) {
      childImageSharp {
        fixed(width: 217, height: 144) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bdmImage: file(relativePath: { eq: "top/bdm.jpg" }) {
      childImageSharp {
        fixed(width: 217, height: 144) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    seimitsuImage: file(relativePath: { eq: "link/seimitsu.png" }) {
      childImageSharp {
        fixed(width: 160, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    virtualUTImage: file(relativePath: { eq: "link/virtualUT_event.png" }) {
      childImageSharp {
        fixed(width: 160, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    KougakubuImage: file(relativePath: { eq: "link/kougakubu.png" }) {
      childImageSharp {
        fixed(width: 160, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kikaiImage: file(relativePath: { eq: "link/kikai.png" }) {
      childImageSharp {
        fixed(width: 160, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    koukuuImage: file(relativePath: { eq: "link/koukuu.png" }) {
      childImageSharp {
        fixed(width: 160, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kaseikeiImage: file(relativePath: { eq: "link/kaseikei.jpeg" }) {
      childImageSharp {
        fixed(width: 90, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    shakaikibanImage: file(relativePath: { eq: "link/shakaikiban.png" }) {
      childImageSharp {
        fixed(width: 160, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    # linkImage: allFile(filter: { relativeDirectory: { eq: "link" } }) {
    #   edges {
    #     node {
    #       name
    #       childImageSharp {
    #         fixed(width: 300) {
    #           ...GatsbyImageSharpFixed
    #         }
    #       }
    #     6
    #   }
    # }
  }
`

export default IndexPage
