// // @ts-check
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CommonEyecatch from "../components/common_eyecatch/index"
import ProjectGroup from "../components/bdmprojects/ProjectGroup"
import { graphql, StaticQuery } from "gatsby"
import worksData from "../works_data/BDMpage_data.json"
import worksImage from "../images/top/bdm.jpg"

const pt30 = {
  paddingTop: 30,
}

const yieldDescription = (title) => {
  switch(title) {
    case 'BDM':
      return '昨年度3年Aセメスターの授業「電子情報機器学」で生まれた作品たちをご紹介します。'
    case 'Infovis':
      return '昨年度3年Aセメスター実験「情報可視化とデータ解析」で生まれた作品たちをご紹介します。'
    case 'opencv':
      return '昨年度3年Aセメスター実験「OpenCV/OpenGLによる映像処理」で生まれた作品たちをご紹介します。'
    default:
      return 'エラー'
  }
}

const WorksPage = () => (
  <StaticQuery
    query={graphql`
      {
        images: allFile(filter: { relativeDirectory: { eq: "BDMpage_img" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO
          title="学生制作物展示"
          pathname="/works/"
          keywords={[
            `電子工作`,
            `EEIC`,
            `電子情報工学科`,
            `電気電子工学科`,
            `東京大学`,
            `工学部`,
            `電気系`,
            `eeic_studentadv`,
          ]}
          description="電気や情報に精通するEEICの学生が作成した、創意工夫ある展示物の数々をご覧ください"
        />
        <CommonEyecatch
          pageTitle="学生制作物展示"
          pageSentences="電気や情報に精通するEEICの学生が作成した<br />創意工夫ある展示物の数々をご覧ください"
          image={worksImage}
          index={3}
        />
        <section className="section-project">
          <div className="project-groups" style={pt30}>
            {Object.keys(worksData).map(key => {
              const filteredData = worksData[key].map(each => {
                const theImage = data.images.edges.find(n => {
                  return n.node.relativePath.includes(each.img[0])
                }).node
                if (!theImage) return null
                return {
                  title: each.title,
                  introduction: each.about_short,
                  headImage: theImage,
                  path: `/works-detail#${each.id}`,
                }
              })
              return (
                <ProjectGroup
                  title={key}
                  description={yieldDescription(key)}
                  color="#D1D656"
                  projectParts={filteredData}
                />
              )
            })}
          </div>
        </section>
      </Layout>
    )}
  />
)

export default WorksPage
