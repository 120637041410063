import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NazotokiDetail from "../components/NazotokiDetail/NazotokiDetail"
import nazotokiImage from "../images/top/nazotoki.png"
import CommonEyecatch from "../components/common_eyecatch"

const NazotokiPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="電気系謎解き"
        pathname="/nazotoki/"
        keywords={[
          `電子工作`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="みつきとえれなから謎解き挑戦状が届いたよ！電気系に関する謎解きに挑戦しよう！"
      />
      <CommonEyecatch
        pageTitle="電気系謎解き"
        pageSentences="みつきとえれなから謎解き挑戦状が届いたよ！<br>電気系に関する謎解きに挑戦しよう！"
        image={nazotokiImage}
        index={2}
      />
      <NazotokiDetail data={projectdata} />
    </Layout>
  )
}

export const projectdata = {
  title: "みつえれからの挑戦状",
  about:
    "みつきとえれなから，みんなへの謎解き挑戦状が届きました。EEICをモチーフとした謎解きに挑戦してみましょう！挑戦状をクリアできたら、みつえれと友達になれるかも…？",
  howToPlay:
    "下のボタンから特設ウェブサイトに移動し、電気系に関する謎を解きましょう。スマートフォン推奨です。",
  url: "https://2021nazotoki.eeic.jp/",
}

export const query = graphql`
  query {
    eworkHeadImage: file(relativePath: { eq: "ework.png" }) {
      ...SectionHeadImage
    }
  }
`

export default NazotokiPage
