import React from "react"
import * as style from "./CraftDetail.module.css"

const sensorContent = () => {
  return (
    <div className={style.container}>
      <h1 className={style.content_title}>ちかちかセンサ電子回路キット</h1>
      <div className={style.movie_holder}>
        <iframe
          className={style.movie}
          src="https://www.youtube.com/embed/E_d9MP8-l6o" 
          title="YouTube video player"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write;encrypted-media;"
          allowfullscreen
        ></iframe>
      </div>
      <div className={style.movie_holder}>
        <iframe
          className={style.movie}
          src="https://www.youtube.com/embed/CsiYGvB4h7U" 
          title="YouTube video player"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write;encrypted-media;"
          allowfullscreen
        ></iframe>
      </div>
      <h2 className={style.border+" "+style.subtitle}>回路の説明</h2>
      <div>
        <p>この回路の最大の特徴は、銀色の線です。これはAgICマーカーというもので描いていて、電気を通します。まずはLEDを光らせてみましょう。</p>
        <p>ステージ２ではコンデンサとトランジスタで無安定マルチバイブレータという回路を作ります。難しそうな名前ですが、仕組みは単純です。トランジスタはスイッチの役割をしていて、片方のコンデンサC1に電気がたまると、トランジスタTR2がオンになります。すると、もう片方のコンデンサC2に電気がたまり、トランジスタTR1がオンになって、、、を繰り返すことでLEDがチカチカ点滅します。</p>
        <p>ステージ３では、センサーによってLEDが点滅するかどうかや、点滅の速さを変えます。赤くて小さい素子はCdSセルといいます。これは光に反応して、明るくなると抵抗が小さくなります。金色の素子は傾斜センサーといいます。これは中に金属のボールが入っていて、傾けると端子（センサーの足）からボールが離れて電気を通さなくなります。なにやら大きくて長いものは、圧力センサーといいます。黒い部分にはいろいろな高さの、電気を流す微小な突起がついています。力を加えると、その突起が裏側の回路に触って電気を流す、つまり抵抗が下がります。このセンサーは急激に抵抗が小さくなるので、優しく押してみてください。</p>
        <p>①の位置にセンサーを付けたとき、抵抗が小さくなるとトランジスタTR２がオンにならなくなって光らなくなります。②の位置にセンサーを付けたときは、抵抗が小さくなると点滅が速くなります。抵抗が小さいほどコンデンサに電気がたまりやすくなるからです。</p>
        <img className={style.img} src={require("../../images/craft/sensor_circuit.jpg")} alt=""/>
      </div>
    </div>
  )
}

export default sensorContent