import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Timer } from "../../images/Timer.svg"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"

const ProjectPart = ({
  time,
  title,
  smallerTitle,
  headImage,
  introduction,
  path,
  spacing,
}) => {
  return (
    <>
      <Link to={path} className="project-part">
        <div>
          <div style={{padding: spacing + "px 0"}}>
            {headImage && (
              <Img
                fixed={headImage.childImageSharp.fixed}
                objectFit="cover"
                objectPosition="center"
                alt=""
              />
            )}
          </div>
          <div className="project-part-title">{title}<span>{smallerTitle}</span></div>
          <div className="project-part-time">
            <Timer />
            <div className="timer">{time}分〜</div>
          </div>
          <div className="project-part-introduction">{introduction}</div>
        </div>
      </Link>
    </>
  )
}

ProjectPart.propTypes = {
  groupTitle: PropTypes.string,
  time: PropTypes.number,
  title: PropTypes.string,
  smallerTitle: PropTypes.string,
  headImage: PropTypes.any,
  introduction: PropTypes.string,
  path: PropTypes.string,
  spacing: PropTypes.number,
}

ProjectPart.defaultProps = {
  groupTitle: "",
  time: 0,
  title: "",
  smallerTitle: "",
  headImage: undefined,
  introduction: "",
  path: "",
  spacing: 0,
}

export default ProjectPart
