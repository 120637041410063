import React, { useState } from "react"
import * as style from "./LiveDetail.module.css"
import liveSchedule from "./LiveSchedule.js"

const LiveDetail = () => {
  console.log(style)
  const [activeDay, setDay] = useState(15)

  return (
    <div className={style.container}>
      <div className="common-detail-wrapper">
        <p style={{ marginTop: 20 }}>
          配信日時: 9/19（日）・9/20（祝）
        </p>
        <p style={{ marginTop: 10 }}>
          EEICオリジナルVTuberみつえれが「近未来体験2021」をご紹介！今すぐライブをチェックしよう！
        </p>
        <div className={style.contentWrapper}>
          <iframe
            className={style.youtube}
            src="https://www.youtube.com/embed/hIh12ywQKjI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <p>配信に関する最新情報は公式Twitterでお知らせしています！</p>
        <div className={style.contentWrapper}>
          <a
            class="twitter-timeline"
            data-lang="ja"
            data-width="600"
            data-height="400"
            href="https://twitter.com/eeic_studentadv?ref_src=twsrc%5Etfw"
            target="_blank"
            rel="noreferrer"
          >
            Tweets by eeic_studentadv
          </a>{" "}
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </div>
        <div className={style.headingWrap}>
          <h2 className={style.heading}>タイムスケジュール</h2>
          <p>＊スケジュールは変更される可能性があります。ご了承下さい。</p>
        </div>
        <div className={style.scheduleDayColumnWrap}>
          <div className={style.scheduleDayColumn}>
            <div className={style.scheduleDayWrap}>
              <span
                className={
                  style.scheduleDay +
                  " " +
                  (activeDay != 19 ? style.scheduleDayInActive : "")
                }
                onClick={() => {
                  setDay(15)
                }}
              >
                9/19
              </span>
            </div>
          </div>
          <div className={style.scheduleDayColumn}>
            <div className={style.scheduleDayWrap}>
              <span
                className={
                  style.scheduleDay +
                  " " +
                  (activeDay != 20 ? style.scheduleDayInActive : "")
                }
                onClick={() => {
                  setDay(20)
                }}
              >
                9/20
              </span>
            </div>
          </div>
        </div>
        <div className={style.scheduleWrap}> 
          {[19, 20].map(day => (
            <div
              className={
                style.scheduleColumn +
                " " +
                (day != activeDay ? style.scheduleInactiveColumn : "")
              }
            >
              <div>
                {liveSchedule
                  .filter(schedule => schedule.day == day)
                  .map(({ hour, minute, name, description }) => {
                    const color =
                      name === "ゲームAI" || name === "カードゲーム"
                        ? "#0095F2"
                        : name === "プログラミング教室" || name ==="電子工作教室"
                        ? "#56D665"
                        : name === "VTuber" || name ==="質問コーナー" || name ==="舞台裏インタビュー"　|| name ==="オープニング"　|| name ==="エンディング"
                        ? "#EC69C6"
                        : name === "研究室展示" || name === "学生制作物展示"
                        ? "#D1D656"
                        : "rgba(255, 255, 255, 1)"
                    return (
                      <div className={style.scheduleRow}>
                        <div className={style.scheduleTime}>
                          {hour.toString().padStart(2, "0")}:
                          {minute.toString().padStart(2, "0")}-
                        </div>
                        <div className={style.scheduleName}>
                          <span
                            style={{
                              color: color,
                            }}
                          >
                            {name}
                          </span>
                        </div>
                        <div className={style.scheduleDescription}>
                          {description}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          ))}
        </div>
       </div>
    </div>
  )
}

// LiveDetail.protoTypes = {
//   data:PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
// }

export default LiveDetail
