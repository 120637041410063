import React from "react"
import * as style from "./CraftDetail.module.css"

const wirelessContent = () => {
  return (
    <div className={style.container}>
      <h1 className={style.content_title}>ぴったりぴかっと無線給電キット</h1>
      <div className={style.movie_holder}>
        <iframe
          className={style.movie}
          src="https://www.youtube.com/embed/40l2FWUpbE0" 
          title="YouTube video player"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write;encrypted-media;"
          allowfullscreen
        ></iframe>
      </div>
      <div className={style.movie_holder}>
      <iframe
          className={style.movie}
          src="https://www.youtube.com/embed/qPtkyQD2AgY"
          title="YouTube video player"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write;encrypted-media;"
          allowfullscreen
        ></iframe>
      </div>
      <h2 className={style.border + " " + style.subtitle}>小学生向けの解説</h2>
      <div>
        <p>
          電池と電球をつなぐと電球が光ります。これは電球に電気が流れるからです。電気はエネルギーの1つで、電球を光らせたり、ものを動かしたりするときにはエネルギーが必要です。みんなのお家のテレビが動いているのは、コンセントからテレビに電気が流れて、テレビがエネルギーをもらっているからです。お父さんお母さんにお願いして、テレビのプラグを抜いてみてください。するとテレビにエネルギーが送られないので、テレビは動きません。電池と電球をつないで、電球が光ったのは、電球が電池からエネルギーをもらったからです。だから、電球だけをつないでも、電球は光りません。これはエネルギーをくれていた電池がないからです。
        </p>
        <img
          className={style.img}
          src={require("../../images/craft/wireless_e.jpg")}
          alt=""
        />
        <p>
          しかし、この「ぴったりぴかっと無線給電キット」では、電池とつながっていない電球（LED）が光ります。どんなからくりなのでしょうか。少し難しいですが、できるだけ簡単に説明をします。詳しく知りたい人は中学生以上向けも読んでみてください。
        </p>
        <p>
          電池と電球は導線を使ってつなぎます。そして、電気は導線を通って電球に流れます。このとき、電球はエネルギーをもらいますが、電球だけじゃなく、導線の周りにもエネルギーが広がっています。このエネルギーを受け取ることできれば、電池とつながっていない電球を光らせることができます。このエネルギーを効率よく送ったり受け取ったりするために、導線をぐるぐる巻きにします。このキットでも電子回路マーカーを使ってぐるぐるのうずを書いてもらいます。このキットでは、電池のつながっているうずからエネルギーが送られ、電球のつながっているうずがエネルギーを受け取ります。なので、2つのうずを近づけるとエネルギーが受け渡しされて、電球が光ります。これがぴったりぴかっと無線給電キットの仕組みです。これは高校や大学で学ぶことができます。楽しみにしていてください。
        </p>
      </div>
      <h2 className={style.border + " " + style.subtitle}>中学生向けの解説</h2>
      <h3 className={style.sub_subtitle}>電気回路</h3>
      <div>
        <p>
          電池、電球（LED）などが導線でつながり環状になったものを電気回路といいます。電気回路について電池、電球、導線から構成される回路を考えます。
        </p>
        <p>
          電池：電気が蓄えられている<br></br>
          電球：電気が流れると光る<br></br>
          導線：電池と電球をつなぐ
        </p>
        <p>
          電池、電球、導線は上記の役割を持っています。電池と電球を導線でつなぐことで、電池に蓄えられていた電気が電球に流れ、電球が光ります。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>磁気現象</h3>
      <div>
        <p>
          磁石は磁石同士で引き寄せあったり、反発しあったりする身近な存在です。磁石の間に働くこれらの力を磁力と呼びます。方位磁針の針が揺れるのは磁力の影響です。接していない物体に力を及ぼすものを考えるときに、ある地点ごとのその作用の大きさを表す物理量として場(field)、磁界を考えます。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>コイル</h3>
      <div>
        <p>
          コイルは導線を螺旋状に巻いて作ったもので、電流を流すと電流の向きと大きさに従って周辺に磁界を生じます。コイルの特性として、コイルの周囲の磁界が変化することか、コイルに流れる電流が変化することによって電圧が生じます。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>交流回路</h3>
      <div>
        <p>
          回路の電源は交流と直流の二つに分けることができます。直流は電源電圧が一定であるのに対し、交流では電源電圧が周期的に変化します。具体例では乾電池が直流でコンセントが交流です。
        </p>
        <p>
          このキットでは発振素子を用いて乾電池の直流電源を交流電源に変化させています。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>電磁誘導</h3>
      <div>
        <p>
          電気回路と磁気現象は別の事象とみなされるが相互に影響を与えています。電流の周りには磁界が生じ、磁界の変化があると回路の起電力が生じます。電気と磁気を結びつける法則が電磁誘導の法則です。電磁誘導の法則は、回路に与える磁界の強さの単位時間あたりの変化量と回路の起電力は比例関係にあるというものです。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>相互誘導</h3>
      <div>
        <p>
          コイルの特性と電磁誘導の法則を用いることで回路として接続されていないLEDを光らせることが可能になります。コイル1に交流電源を加えることで電流の時間変化が生じ、磁界が発生します。もう片方のコイル2が時間変化する磁界の影響を受けると、「磁界の変化によって電圧が生じる」コイルの特性で回路に起電力ができます。磁界は周期的に変化し続けるので、その時間変化に比例して、コイル2が交流電源として働きます。それによりコイル2につながったLEDは光ることが可能になります。
        </p>
        <p>
          この相互誘導という磁気を介して電源を伝える物理現象により回路がつながっていなくてもLEDを光らせることが可能になります。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>無線給電の実用例</h3>
      <div>
        <p>
          無線給電（ワイヤレス給電）はすでに私たちの身の回りでも実用化されています。最も分かりやすい例では、スマートフォンの置くだけで充電できる機能が挙げられます。また水回りの電子機器は、漏電防止のため端子を露出しない作りとなることが多く、電動歯ブラシや電動シェーバーにも無線給電が使われています。さらに未来に話を移すと夢が広がります。公道での電気自動車の無線給電が研究されており、これが実現すると理論上走り続ける車というのも考えられます。また、JAXAでは宇宙太陽光発電システムという研究があり、宇宙空間で発電したエネルギーを無線給電で地表に届けるという内容です。この発電方法の利点は天気に左右されることなく24時間365日発電をできることです。これに興味を持った方はぜひ無線給電について検索したり研究したりしてみてください。EEICにも無線給電を研究している研究室があります。
        </p>
      </div>
      <h3 className={style.sub_subtitle}>リンク集</h3>
      <div>
        <p>
          <a
            className={style.link}
            href="http://hflab.k.u-tokyo.ac.jp/research_hfl_ja.html"
            target="_blank"
            rel="noreferrer"
          >
            堀・藤本研究室　研究紹介
          </a>
        </p>
        <p>
          <a
            className={style.link}
            href="https://www.kenkai.jaxa.jp/research/ssps/ssps-ssps.html"
            target="_blank"
            rel="noreferrer"
          >
            JAXA研究開発部門　宇宙太陽光発電システムについて
          </a>
        </p>
      </div>
    </div>
  )
}

export default wirelessContent
