import PropTypes from "prop-types"
import React from "react"
import * as style from "./GameDetail.module.css"

const gameDetail = ({data}) => {
  return (
    <div className={style.container}>
      <div className="common-detail-wrapper"> 
        {
          data.map(game => {
            return (
              <>
                <div className={style.title_holder}>
                  <h1 className={style.title+" "+style.border}>{game.title}</h1>
                </div>
                <div className={style.container+" "+style.flex}> 
                  <div className={style.container+" "+style.subcontent}>
                    <img className={style.img} src={require("../../images/game/" + game.img)} alt=""/>
                  </div>
                  <div className={style.container+" "+style.subcontent}> 
                    <div className={style.py10}>
                      <h2 className={style.border+" "+style.subtitle}>ABOUT</h2>
                      <div>{game.about}</div> 
                    </div>
                    {
                      game.title === "論理回路" &&
                      (
                      <div className={style.py10}>
                        <a className={style.button} href={game.url} target="_blank" rel="noreferrer">
                          やってみる
                        </a>
                        <div>*外部サイトにリンクします</div>
                      </div> 
                      )
                    }
                  </div>
                </div>
                {game.title === "ゲームAI" &&
                  (
                  <div>
                    <div className={style.game_frame}>
                      <iframe className={style.frame} src={game.url}></iframe>
                      <div>※キーボード操作ができない場合は画面内をクリックしてください。</div>
                    </div>
                    <div className={style.py10}>
                      <a className={style.button} href={game.url} target="_blank" rel="noreferrer">
                        うまく動かない場合はこちら
                      </a>
                      <div>*外部サイトにリンクします</div>
                    </div> 
                  </div>
                  )
                }
              </>
            )
          })
        }

      </div>
    </div>
  )
}

gameDetail.protoTypes = {
  data:PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
}

export default gameDetail