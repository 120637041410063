import React from "react"
import * as style from "./CraftDetail.module.css"

const paperContent = () => {
  return (
    <div className={style.container}>
      <h1 className={style.content_title}>きらきらくるくるペーパークラフトキット</h1>
      <div className={style.movie_holder}>
        <iframe
          className={style.movie}
          src="https://www.youtube.com/embed/kt4xVLX6BIc"
          title="YouTube video player"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write;encrypted-media;"
          allowfullscreen
        ></iframe>
      </div>
      <div className={style.movie_holder}>
      <iframe
          className={style.movie}
          src="https://www.youtube.com/embed/8_yJJ1zPZGk"
          title="YouTube video player"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write;encrypted-media;"
          allowfullscreen
        ></iframe>
      </div>
      <h2 className={style.border+" "+style.subtitle}>キットの説明</h2>
      <div>
        <p>紙に回路を描くというAgICの特性を活かして、電子回路とペーパークラフトを組み合わせました。</p>
        <p>作品全体は、台座部分・側面部分・屋根部分の３箇所に別れています。</p>
        <p>台座部分は乾電池２本を電源として、モータを回すと同時に白色LEDを光らせます。モータの回転速度が早いので、歯車を組み合わせることで回転速度を遅くしています(黄色い箱のギヤボックス部分)。この回転エネルギーを回転軸に伝えることでペーパークラフトを回転させます。またペーパークラフトを内側から光らせるために、輝度の大きいLEDを採用しました。</p>
        <p>側面部分はシンプルなペーパークラフトです。紙を切って組み合わせることで、綺麗な正七角柱を作ることができます。上部は屋根との接合のためにのりしろを作ります。</p>
        <p>屋根部分が電子回路になっています。ボタン電池、LED、スイッチといった素子を組み合わせて回路を作成します。素子間を回路マーカー(AgICペン)でつなぎ、回路を完成させます。回路をペンで描いて完成させ、それを折って貼り付けることで屋根部分を作ります。</p>
        <p>この３つの部分を組み合わせて「きらきらくるくるペーパークラフトキット」の完成です！</p>
      </div>
    </div>
  )
}

export default paperContent