import React from "react"
import PropTypes from "prop-types"
// import { ReactComponent as Timer } from "../../images/Timer.svg"
import Img from "gatsby-image/withIEPolyfill"

const LinkPart = ({
  title,
  subTitle,
  headImage,
  path,
}) => {
  return (
    <>
      <a href={path} className="link-part" target="_blank" rel="noreferrer">
        <div>
          <div style={{objectFit: "contain" }}>
            {headImage && (
              <Img
                fixed={headImage.childImageSharp.fixed}
                objectFit="contain"
                objectPosition="center"
                alt=""
              />
            )}
          </div>
          <div className="link-part-title">{title}</div>
          <div className="link-part-subTitle">{subTitle}</div>
        </div>
      </a>
    </>
  )
}

LinkPart.propTypes = {
  groupTitle: PropTypes.string,
  title: PropTypes.string,
  headImage: PropTypes.any,
  introduction: PropTypes.string,
  path: PropTypes.string,
}

LinkPart.defaultProps = {
  groupTitle: "",
  title: "",
  headImage: undefined,
  introduction: "",
  path: "",
}

const LinkTiles = ({ LinkParts, title, subTitle, color }) => {
  const css = `.project-group#${title} .project-group-title { border-bottom: 3px solid ${color}; }\n .project-group#${title} .project-part { border: 3px solid ${color}; }`
  return (
    <>
      <div className="project-group" id={title}>
        <div className="link-tiles-content">
          {LinkParts.map(
            ({title, subTitle, headImage, path}) => (
              <LinkPart
                {...{title, subTitle, headImage, path}}
              />
            )
          )}
        </div>
      </div>
      <style>{css}</style>
    </>
  )
}

LinkTiles.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  LinkParts: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      title: PropTypes.string,
      smallerTitle: PropTypes.string,
      headImage: PropTypes.any,
      introduction: PropTypes.string,
      path: PropTypes.string,
      spacing: PropTypes.bool,
    })
  ),
}

export default LinkTiles
