import React from "react"
import PropTypes from "prop-types"
import ProjectPart from "./ProjectPart"
import { ReactComponent as BulbWhite } from "../../images/bulb-white.svg"

const ProjectGroup = ({ projectParts, title, color }) => {
  const css = `.project-group#${title} .project-group-title { border-bottom: 3px solid ${color}; }\n .project-group#${title} .project-part { border: 3px solid ${color}; }`
  return (
    <>
      <div className="project-group" id={title}>
        <div className="project-group-title">
          <div className="content">
            <BulbWhite height="28" className="bulb" />
            <div>{title}</div>
          </div>
        </div>
        <div className="project-group-content">
          {projectParts.map(
            ({ time, title, smallerTitle, headImage, introduction, path, spacing }, i) => (
              <ProjectPart
                key={i}
                {...{ time, title, smallerTitle, headImage, introduction, path, spacing }}
              />
            )
          )}
        </div>
      </div>
      <style>{css}</style>
    </>
  )
}

ProjectGroup.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  projectParts: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      title: PropTypes.string,
      smallerTitle: PropTypes.string,
      headImage: PropTypes.any,
      introduction: PropTypes.string,
      path: PropTypes.string,
      spacing: PropTypes.number,
    })
  ),
}

export default ProjectGroup
