import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProgrammingDetail from "../components/ProgrammingDetail/ProgrammingDetail"
import prImage from "../images/top/programming.png"
import CommonEyecatch from "../components/common_eyecatch"

const ProgrammingPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="プログラミング教室"
        pathname="/programming/"
        keywords={[
          `プログラミング`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="プログラミング教室では電子情報工学科・電気電子工学科の学生がプログラミングを学べるゲームを作成・展示しています。"
      />
      <CommonEyecatch
        pageTitle="プログラミング教室"
        pageSentences="プログラミングを学べるゲームを作成・展示しています。"
        image={prImage}
        index={1}
      />
      <ProgrammingDetail data={pkyoData} />
    </Layout>
  )
}

export const pkyoData = {
  title: "プログラミング教室",
  about: "ゲームを通してプログラミングの知識に触れてみよう！初心者から経験者までが楽しめるゲームです。",
  url_howtoplay: "https://2021pkyo.eeic.jp/commentary/howtoplay.html",
  url_game: "https://2021pkyo.eeic.jp",
  img: "programming.png",
}

export default ProgrammingPage
