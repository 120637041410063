import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CommonEyecatch from "../components/common_eyecatch/index"
import Section from "../components/section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <CommonEyecatch pageTitle="404" pageSentences="Not found" index={0} />
    <Section>
      <h3>NOT FOUND</h3>
      <p>このページは存在しません。</p>
    </Section>
  </Layout>
)

export default NotFoundPage
