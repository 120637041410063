import PropTypes from "prop-types"
import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as style from "./worksDetail.module.css"

const WorksDetail = ({ data, category }) => {
  return (
    <div className={style.container}>
      <div className="common-detail-wrapper">
        <div className={style.title_holder}>
          <h1 className={style.title + " " + style.border}>{category}</h1>
        </div>
        {data.map(work => {
          return (
            <div className={style.work_Container} name={work.id}>
              <div className={style.container + " " + style.flex}>
                <div className={style.py10 + " " + style.subcontent}>
                  <h2 className={style.border + " " + style.subtitle}>
                    作品名
                  </h2>
                  <div className={style.work_title}>{work.title}</div>
                  <div className={style.pb10}>制作者：{work.creator}</div>
                </div>
                <div className={style.container + " " + style.subcontent}>
                  <Slider className="slider" {...carousel_settings}>
                    {work.img.map(img => {
                      return (
                        <img
                          className={style.img}
                          src={require("../../images/BDMpage_img/" + img)}
                          alt="works"
                        />
                      )
                    })}
                  </Slider>
                </div>
              </div>
              <div className={style.container + " " + style.flex}>
                <div className={style.py10 + " " + style.subcontent}>
                  <h2 className={style.border + " " + style.subtitle}>概要</h2>
                  <div>{work.about_long}</div>
                </div>
                <div className={style.py10 + " " + style.subcontent}>
                  <h2 className={style.border + " " + style.subtitle}>
                    作成方法
                  </h2>
                  <div>{work.method}</div>
                </div>
              </div>
              {work.movie && (
                <div className={style.movie_holder}>
                  <iframe
                    className={style.movie}
                    src={work.movie}
                    title="YouTube video player"
                    frameborder="0"
                    scrolling="no"
                    allow="accelerometer; clipboard-write;encrypted-media;"
                    allowfullscreen
                  ></iframe>
                </div>
              )}
              <hr class={style.hrline}></hr>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const carousel_settings = {
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

WorksDetail.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  category: PropTypes.string,
}

export default WorksDetail
