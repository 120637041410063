import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CommonEyecatch from "../components/common_eyecatch/index"
import CraftDetail from "../components/CraftDetail/CraftDetail"
import craftImage from "../images/top/craft.jpeg"

const CraftPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="電子工作教室"
        pathname="/craft/"
        keywords={[
          `電子工作`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="AgIC回路マーカーを使ったかんたん電子工作キットをご紹介！"
      />
      <CommonEyecatch
        pageTitle="電子工作教室"
        pageSentences="AgIC回路マーカーを使ったかんたん電子工作キットをご紹介！"
        image={craftImage}
        index={1}
      />
      <CraftDetail data={craftData} />
    </Layout>
  )
}

export const craftData = [
  {
    id: "paper",
    title: "きらきらくるくる<br>ペーパークラフトキット",
    img: "paper.jpg",
  },
  {
    id: "sensor",
    title: "ちかちかセンサ<br>電子回路キット",
    img: "sensor.jpg",
  },
  {
    id: "wireless",
    title: "ぴったりぴかっと<br>無線給電キット",
    img: "wireless.jpg",
  }
]

export default CraftPage
