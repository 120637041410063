// @ts-check
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  const scriptUrls = [
    `https://apis.google.com/js/platform.js`,
    `https://platform.twitter.com/widgets.js`,
  ]
  for (const url of scriptUrls) {
    const s = document.createElement(`script`)
    s.src = url
    s.charset = `utf-8`
    s.async = true
    document.getElementsByTagName(`head`)[0].appendChild(s)
  }
}

/** headerがスクロール0で隠れる処理 */
const hideANDSeekHeader = () => {
  const thisHTML = document.getElementsByTagName("html")[0]
  const headers = document.getElementsByTagName("header")
  if (headers.length > 1) {
    headers[0].style.background = "rgba(0, 40, 54, 0.1)"
    headers[1].style.background = "rgba(0, 40, 54, 0.1)"
  }
  document.addEventListener("scroll", () => {
    const scrollAmount = thisHTML.scrollTop
    if (headers.length > 1) {
      if (scrollAmount === 0) {
        headers[0].style.background = "rgba(0, 40, 54, 0.1)"
        headers[1].style.background = "rgba(0, 40, 54, 0.1)"
      } else {
        headers[0].style.background = "rgba(0, 40, 54, 0.88)"
        headers[1].style.background = "rgba(0, 40, 54, 0.88)"
      }
    }
  })
}

/** ページ内リンク実装 by name */
const setInPageLink = location => {
  setTimeout(() => {
    const theNameElementCandidate = document.getElementsByName(
      location.hash.slice(1)
    )
    if (theNameElementCandidate.length === 0) return
    const theNameElement = theNameElementCandidate[0]
    const yOffset = theNameElement.getBoundingClientRect().top
    console.log(yOffset)
    window.scrollBy(0, yOffset - 60)
  }, 1000)
}

exports.onRouteUpdate = ({ location }) => {
  hideANDSeekHeader()
  if (location.hash) setInPageLink(location)
}
