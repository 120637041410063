import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GameDetail from "../components/GameDetail/GameDetail"
import gameImage from "../images/top/game.png"
import CommonEyecatch from "../components/common_eyecatch"

const GamePage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="ゲーム（AI・論理回路）"
        pathname="/game/"
        keywords={[
          `ゲーム`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="ゲームを楽しむ EEICに親しむ"
      />
      <CommonEyecatch
        pageTitle="ゲーム（AI・論理回路）"
        pageSentences="ゲームを楽しむ　EEICに親しむ"
        image={gameImage}
        index={2}
      />
      <GameDetail data={gamesData} />
    </Layout>
  )
}

export const gamesData = [
  {
    title: "ゲームAI",
    about:
      "トイカーリングというゲームを題材に、時間が経つにつれて強くなっていくAIをみんなで見守ろう！　EEICで行われる実験の一つ、人工知能演習の内容を参考に作りました。1分程度で遊ぶことができます。",
    img: "gameAI.png",
    url: "https://gameai.eeic.ebiyuu.com/",
    show: true,
  },
  {
    title: "論理回路",
    about:
      "ボードゲームで遊んでEEICの学習内容の一端に触れよう！　基本となるのは0と1の組をルールに従って別の値にかえること。パソコンの中で動いている0と1のルールを遊びながら学ぶことができます。",
    img: "logic_gate.png",
    url: "https://logic-circuit-game-server.uc.r.appspot.com/front.html",
    show: true,
  },
]

export default GamePage
