// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-craft-js": () => import("./../../../src/pages/craft.js" /* webpackChunkName: "component---src-pages-craft-js" */),
  "component---src-pages-game-js": () => import("./../../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-nazotoki-js": () => import("./../../../src/pages/nazotoki.js" /* webpackChunkName: "component---src-pages-nazotoki-js" */),
  "component---src-pages-programming-js": () => import("./../../../src/pages/programming.js" /* webpackChunkName: "component---src-pages-programming-js" */),
  "component---src-pages-works-detail-js": () => import("./../../../src/pages/works-detail.js" /* webpackChunkName: "component---src-pages-works-detail-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

