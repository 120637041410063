import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LabDetail from "../components/LabDetail/LabDetail"
import CommonEyecatch from "../components/common_eyecatch"
import labImage from "../images/top/lab.png"

const LabPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="研究室展示"
        pathname="/lab/"
        keywords={[
          `研究室`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="ナノ制御から太陽プラズマまで、電気の研究内容をご紹介！"
      />
      <CommonEyecatch
        pageTitle="研究室展示"
        pageSentences="ナノ制御から太陽プラズマまで、電気の研究内容をご紹介！"
        image={labImage}
        index={3}
      />
      <LabDetail data={labsData} />
    </Layout>
  )
}

export const labsData = [
  {
    labname: "山崎研",
    about: "VTuber「みつえれ」とのスペシャル対談！",
    live_date:"配信日時：調整中", // live_date: "近日公開",
    image: ["yamasaki.png"],
    link: "http://www.hal.t.u-tokyo.ac.jp/lab/ja/index_1.xhtml",
  },
  {
    labname: "苗村研",
    about: "拡張実現感、ヒューマンインターフェース、屋外画像認識",
    live_date:"配信日時：調整中", // live_date: "近日公開",
    image: ["naemura.png"],
    link: "https://nae-lab.org/",
  },
  {
    labname: "廣瀨・夏秋研",
    about: "地球を見てみよう、それもレーダーで。",
    live_date:"配信日時", // live_date: "【生配信】5/16, 15:00-",
    image: ["hirose.png","natsuaki.png"],
    link: "https://www.eis.t.u-tokyo.ac.jp/",
  },
  {
    labname: "高木・竹中研",
    about: "デバイスの要「クリーンルーム」大見学会開催！",
    live_date:"配信日時：調整中", // live_date: "【生配信】5/15, 15:50-",
    image: ["takagi.png", "takenaka.png"],
    link: "http://www.mosfet.k.u-tokyo.ac.jp/",
  },
  {
    labname: "三田研",
    about: "大きな装置で小さなマシンーMEMS研究紹介ー",
    live_date:"配信日時：調整中", // live_date: "【生配信】5/16, 15:45-",
    image: ["mita.png"],
    link: "https://www.if.t.u-tokyo.ac.jp/",
  },
  {
    labname: "坂井・入江研",
    about: "VR空間で受肉して研究紹介してみた！",
    live_date:"配信日時：調整中", // live_date: "【生配信】5/15, 14:00-",
    image: ["sakai.png","irie.png"],
    link: "https://www.mtl.t.u-tokyo.ac.jp/",
  },
  {
    labname: "相澤・松井研",
    about: "画像認識で食事管理！？Food Logに迫る",
    live_date:"配信日時：調整中", // live_date: "近日公開",
    image: ["aizawa.png", "matui.png"],
    link: "http://www.hal.t.u-tokyo.ac.jp/lab/ja/index_1.xhtml",
  },
  {
    labname: "川原研",
    about: "部屋にいるだけで充電！？無線給電の最先端",
    live_date:"配信日時：調整中", // live_date: "【生配信】5/16, 13:05-",
    image: ["kawahara.png"],
    link: "https://www.akg.t.u-tokyo.ac.jp/",
  },
  {
    labname: "古関研",
    about: "運ぶ科学ー電気で人と物を動かそうー",
    live_date:"配信日時：調整中", // live_date: "近日公開",
    image: ["koseki.png"],
    link: "https://koseki.t.u-tokyo.ac.jp/",
  },
  {
    labname: "関野研",
    about: "工学で拓く医療デバイスー制作物紹介ー",
    live_date:"配信日時：調整中", // live_date: "近日公開",
    image: ["sekino.png"],
    link: "http://www.bee.t.u-tokyo.ac.jp/index_j.html",
  },
  {
    labname: "藤本研",
    about: "制御の力で世界が変わる！無線給電",
    live_date:"配信日時：調整中", // live_date: "【生配信】5/16, 13:30-",
    image: ["fujimoto.jpg"],
    link: "https://hflab.edu.k.u-tokyo.ac.jp/",
  },
  {
    labname: "小野靖研",
    about: "次世代発電「プラズマ核融合」のメカニズム",
    live_date:"配信日時：調整中", // live_date: "【生配信】5/16, 14:35-",
    image: ["ono.png"],
    link: "http://tanuki.t.u-tokyo.ac.jp/",
  },
]

export default LabPage
