import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import works_data from "../works_data/BDMpage_data.json"
import WorksDetail from "../components/worksDetail/worksDetail"
import CommonEyecatch from "../components/common_eyecatch"
import worksImage from "../images/top/bdm.jpg"

const WorksDetailPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="学生制作物展示"
        pathname="/works/"
        keywords={[
          `電子工作`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="電気や情報に精通するEEICの学生が作成した、創意工夫ある展示物の数々をご覧ください"
      />
      <CommonEyecatch
        pageTitle="学生制作物展示"
        pageSentences="電気や情報に精通するEEICの学生が作成した<br />創意工夫ある展示物の数々をご覧ください"
        image={worksImage}
        index={3}
      />
      <WorksDetail data={works_data["BDM"]} category="BDM" />
      <WorksDetail data={works_data["Infovis"]} category="情報可視化" />
      <WorksDetail data={works_data["opencv"]} category="openCV/openGL" />
    </Layout>
  )
}

export default WorksDetailPage
