import React from "react"
import PropTypes from "prop-types"
import ProjectPart from "./ProjectPart"
import { ReactComponent as BulbWhite } from "../../images/bulb-white.svg"

const setDescription = (desc) => {
  return desc ? <p>{desc}</p> : <></>
}

const ProjectGroup = ({ projectParts, title, description, color }) => {
  const css = `.project-group#${title} .project-group-title { border-bottom: 3px solid ${color}; }\n .project-group#${title} .project-part { border: 3px solid ${color}; }`
  return (
    <>
      <div className="project-group" id={title}>
        <div className="project-group-title">
          <div className="content">
            <BulbWhite height="28" className="bulb" />
            <div>{title}</div>
          </div>
        </div>
        {setDescription(description)}
        <div className="project-group-content">
          {projectParts.map(
            ({ time, title, smallerTitle, headImage, introduction, path, spacing }) => (
              <ProjectPart
                {...{ time, title, smallerTitle, headImage, introduction, path, spacing, color }}
              />
            )
          )}
        </div>
      </div>
      <style>{css}</style>
    </>
  )
}

ProjectGroup.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  projectParts: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      title: PropTypes.string,
      smallerTitle: PropTypes.string,
      headImage: PropTypes.any,
      introduction: PropTypes.string,
      path: PropTypes.string,
      spacing: PropTypes.bool,
    })
  ),
}

export default ProjectGroup
