import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Hero = ({ pageTitle, pageSubtitle, className, children }) => (
  <StaticQuery
    // query={graphql`
    //   query {
    //     bgimage: file(relativePath: { eq: "toppic.jpg" }) {
    //       childImageSharp {
    //         fluid(maxWidth: 4160) {
    //           ...GatsbyImageSharpFluid_withWebp
    //         }
    //       }
    //     }
    //   }
    // `}
    query={graphql`
      query {
        bgimage: file(relativePath: { eq: "top_toppic.png" }) {
          childImageSharp {
            fluid(maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bgimage_mobile: file(relativePath: { eq: "top_toppic_mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section className={`hero ${className || ``}`}>
        <Img
          className="hero-blur"
          fluid={data.bgimage.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="5% 50%"
        />
        <Img
          className="hero-blur-mobile"
          fluid={data.bgimage_mobile.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        {children || (
          <>
            <h2>{pageTitle}</h2>
            <p className="subtitle">{pageSubtitle}</p>
          </>
        )}
      </section>
    )}
  />
)

Hero.propTypes = {
  pageTitle: PropTypes.string,
  pageSubtitle: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

Hero.defaultProps = {
  pageTitle: ``,
  pageSubtitle: ``,
}

export default Hero
