const menuContents = [
    {menuTitle: "配信を見る",
    contents: [
        {title: "配信ページ", link : "/live/"},
        // {title: "タイムテーブル", link: "/live#timetable/"}
    ]},
    {menuTitle: "つくる",
    contents:  [
        {title: "電子工作教室", link: "/craft/"}, 
        {title: "プログラミング教室", link: "/programming/"}
    ]},
    
    {menuTitle: "あそぶ",
    contents: [
        {title: "電気系謎解き", link: "/nazotoki/"},
        {title: "ゲーム(AI・論理回路)", link: "/game/" }
    ]},

    {menuTitle: "EEICとは",
    contents: [
        {title: "研究室紹介", link:"/lab/"}, 
        {title: "学生制作物展示", link: "/works/"}
    ]}
    ]
export default menuContents;