import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"

const ProjectPart = ({
  time,
  title,
  smallerTitle,
  headImage,
  introduction,
  path,
  spacing,
  color,
}) => {

  return (
    <>
      <Link to={path} className="hover-scale" style={wrapperStyle({ color })}>
        <div style={{ position: 'relative' }}>
          <div style={spacerStyle}></div>
          <div style={{...containerStyle}}>
            {headImage && (
              <Img
                fixed={headImage.childImageSharp.fixed}
                objectFit="cover"
                objectPosition="center"
                alt=""
              />
            )}
          </div>
          <div style={{...containerStyle, ...textWrapperStyle}}>
            <div style={titleStyle({ color })}>{title}</div>
            <div style={{ padding: '0 4px', fontSize: '14px' }}>{introduction}</div>
            <div><span style={{ color: color }}>›</span> More</div>
          </div>
        </div>
      </Link>
    </>
  )
}

const wrapperStyle = ({
  color
}) => ({
  display: 'block',
  width: '228px',
  margin: '0 12px 24px',
  border: `${color} 4px solid`,
  borderRadius: '8px',
  overflow: 'hidden',
})

const spacerStyle = {
  width: '100%',
  paddingTop: '75%'
}

const containerStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
}

const textWrapperStyle = {
  padding: '5px 10px',
  background: 'rgba(0, 0, 0, 0.65)',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const titleStyle = ({
  color
}) => ({
  borderBottom: `2px solid ${color}`
})

ProjectPart.propTypes = {
  groupTitle: PropTypes.string,
  time: PropTypes.number,
  title: PropTypes.string,
  smallerTitle: PropTypes.string,
  headImage: PropTypes.any,
  introduction: PropTypes.string,
  path: PropTypes.string,
  spacing: PropTypes.number,
}

ProjectPart.defaultProps = {
  groupTitle: "",
  time: 0,
  title: "",
  smallerTitle: "",
  headImage: undefined,
  introduction: "",
  path: "",
  spacing: 0,
}

export default ProjectPart
