import { Link } from "gatsby"
import React from "react"

const MyDropdown = ({ menuContent, index }) => {
  const colorArray = ["#ec69c6", "#56d665", "#22abe8", "#d1d656"]
  const menuTitleStyle = {
    fontSize: "25px",
    textDecoration: "none",
    textDecorationColor: colorArray[index],
  }

  return (
    <ul className="menu-container-header">
      <li className="menu__single">
        <a className={menuContent.menuTitle} style={menuTitleStyle}>
          {menuContent.menuTitle}
        </a>
        <ul className="menu__second-level">
          {menuContent.contents.map((oneContent, i) => (
            <li className={menuContent.menuTitle} style={{ fontSize: "13px" }} key={menuContent.menuTitle + i}>
              <Link
                className={menuContent.menuTitle}
                style={{ textDecoration: "none" }}
                to={oneContent.link}
              >
                {oneContent.title}
              </Link>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  )
}
export default MyDropdown
