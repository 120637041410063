import PropTypes from "prop-types"
import React from "react"
import * as style from "./NazotokiDetail.module.css"
import IMG from "../../images/top/nazotoki.png"

const NazotokiDetail = ({data}) => {
  return (
    <div className={style.container}>
      <div className="common-detail-wrapper">
        <div className={style.title_holder}>
          <h1 className={style.title+" "+style.border}>{data.title}</h1>
        </div>
        <div className={style.container+" "+style.flex}> 
          <div className={style.container+" "+style.subcontent}>
            <img className={style.img} src={IMG} alt=""/>
          </div>
          <div className={style.container+" "+style.subcontent}> 
            <div>所要時間　15~30分　　対象年齢　全年齢</div> 
            <div className={style.py10}>
              <h2 className={style.border+" "+style.subtitle}>ABOUT</h2>
              <div>{data.about}</div> 
            </div> 
            <div className={style.py10}>
              <a className={style.button} href={data.url} target="_blank" rel="noreferrer">
                やってみる
              </a>
              <div>*外部サイトにリンクします</div>
            </div> 
          </div>
        </div>
      </div>
    </div>  
  )
}

NazotokiDetail.propTypes = {
  data:PropTypes.shape(PropTypes.string)
}

export default NazotokiDetail