import * as stylePhone from "./header-smartphone.module.css"
import React, { useState } from "react"
import menuContents from "./menuContents"
import { Link } from "gatsby"
import { ReactComponent as BulbWhite } from "../../images/bulb-white.svg"
import { ReactComponent as YouTubeIcon } from "../../images/YouTube.svg"
import { ReactComponent as TwitterIcon } from "../../images/twitter.svg"

const Sidebar = () => {
  const [open, setOpen] = useState(false)
  const [initialClick, setInitialClick] = useState(true)

  const openSideBar = () => {
    setInitialClick(false)
    setOpen(true)
  }
  return (
    <>
      {(initialClick && (
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events
         className={stylePhone.menuIcon} role="button" tabIndex="0" onClick={openSideBar}>
          <div className={stylePhone.line}>
            <div className={stylePhone.span}></div>
            <div className={stylePhone.span}></div>
            <div className={stylePhone.span}></div>
          </div>
          <div className={stylePhone.menutext}>MENU</div>
        </div>
      )) || (
        <>
          <div // eslint-disable-line jsx-a11y/click-events-have-key-events
            className={open ? stylePhone.closeIcon : stylePhone.menuIcon}
            onClick={() => setOpen(!open)} role="button" tabIndex="0"
          >
            <div className={stylePhone.line}>
              <div
                className={open ? stylePhone.closespan : stylePhone.openspan}
              ></div>
              <div
                className={open ? stylePhone.closespan : stylePhone.openspan}
              ></div>
              <div
                className={open ? stylePhone.closespan : stylePhone.openspan}
              ></div>
            </div>
            <div className={stylePhone.menutext}>{open ? "CLOSE" : "MENU"}</div>
          </div>
          <div className={open ? stylePhone.visible : stylePhone.unvisible}>
            <div className={stylePhone.sidebarTop}>
              <div className={stylePhone.sidebarTitle}>
                <div className={stylePhone.sidebarTitleSmall}>
                  EEIC
                  <BulbWhite
                    className={
                      open ? stylePhone.bulbIcon : stylePhone.bulbIconUnvisible
                    }
                  />
                </div>
                <div className={stylePhone.sidebarTitleMain}>
                  近未来体験2021
                </div>
              </div>
            </div>
            <div className={stylePhone.sidebarInner}>
              <div className={stylePhone.sidebarContents}>
                <div className={stylePhone.sidebarContOnly}>
                  <Link className={stylePhone.link} to="/">
                    <div className={stylePhone.sidebarContTitle}>
                      <span>
                        ホーム
                      </span>
                    </div>
                  </Link>
                </div>

                {menuContents.map((content, i) => (
                  <div className={stylePhone.sidebarCont} key={i}>
                    <div className={stylePhone.menuTitle}>
                      {content.menuTitle}
                    </div>
                    {content.contents.map((cont, j) => (
                      <Link
                        className={stylePhone.link}
                        to={cont.link}
                        key={j}
                        onClick={() => setOpen(false)}
                      >
                        <div className={stylePhone.contTitle}>
                          <span>{cont.title}</span>
                        </div>
                      </Link>
                    ))}
                  </div>
                ))}

                <div className={stylePhone.sidebarContOnly}>
                  <Link className={stylePhone.link} to="https://www.youtube.com/channel/UCWPrxA-tgo7ePClMeZhMn0g">
                    <div className={stylePhone.sidebarContTitle}>
                      <span>
                        公式Youtube
                      </span>
                      <YouTubeIcon className={stylePhone.sidebarIcon} />
                    </div>
                  </Link>
                </div>
                <div className={stylePhone.sidebarContOnly}>
                  <Link className={stylePhone.link} to="https://twitter.com/mitsuele_eeic">
                    <div className={stylePhone.sidebarContTitle}>
                      <span>
                        公式Twitter
                      </span>
                      <TwitterIcon className={stylePhone.sidebarIcon} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Sidebar
