import PropTypes from "prop-types"
import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as style from "./LabDetail.module.css"

const labDetail = ({data}) => {
  return (
    <div className={style.container}>
      <div className="common-detail-wrapper">
        <div className={style.title_holder}>
          <h1 className={style.title+" "+style.border}>研究室展示ページ</h1>
          <div>EEICの研究室とみつえれの対談企画が進行中！ 配信時刻をチェックしよう</div>
          <div>合わせて、EEICに関する研究室について情報がまとめられた「電気の回廊」も、ぜひご覧ください</div>
          <div className={style.py10}>
            <div> 
              <a className={style.button} href="http://nanotechnet.t.u-tokyo.ac.jp/kairo/index.html" target="_blank" rel="noreferrer">電気の回廊を見てみる</a>
            </div>
            <div>*外部サイトにリンクします</div>
          </div>
        </div>
        <div className={style.container+" "+style.flex}> 
          {
            data.map(lab => {
              return (
                <div className={style.card}>
                  <div>
                    <Slider className="slider" {...carousel_settings}>
                      {lab.image.map(img => {
                        return (
                          <img
                            className={style.img}
                            src={require("../../images/professor/" + img)}
                            alt="professor"
                          />
                        )
                      })}
                    </Slider>
                  </div>
                  <div className={style.container}>
                    <div className={style.subtitle}>{lab.labname}</div>
                    <div>{lab.live_date}</div>
                    <div className={style.description}>{lab.about}</div>
                    <div className={style.center}>
                      <a className={style.button} href={lab.link} target="_blank" rel="noreferrer">
                        研究室HPはこちら
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

const carousel_settings = {
  arrows: false,
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

labDetail.protoTypes = {
  data:PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
}

export default labDetail 