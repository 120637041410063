const schedule = [
  { name: "オープニング", day: 19, hour: 13, minute: 0, description: "" },
  {
    name: "ゲームAI",
    day: 19,
    hour: 13,
    minute: 5,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },

  {
    name: "学生制作物展示",
    day: 19,
    hour: 13,
    minute: 15,
    description: "学生が授業で作った制作物を紹介！",
  },

  {
    name: "VTuber",
    day: 19,
    hour: 13,
    minute: 30,
    description: "みつき・えれなの秘密を紹介します！",
  },

  {
    name: "研究室展示",
    day: 19,
    hour: 13,
    minute: 50,
    description: "VR空間で受肉して研究紹介してみた！",
  },

  {
    name: "プログラミング教室",
    day: 19,
    hour: 14,
    minute: 15,
    description: "WEBで公開中のプログラミング教室にみつえれが挑戦！",
  },

  {
    name: "学生制作物展示",
    day: 19,
    hour: 15,
    minute: 0,
    description: "学生が授業で作った制作物を紹介！",
  },

  {
    name: "研究室展示",
    day: 19,
    hour: 15,
    minute: 15,
    description: "デバイスの要「クリーンルーム」大見学会開催！",
  },
 
  {
    name: "ゲームAI",
    day: 19,
    hour: 15,
    minute: 40,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },

  {
    name: "電子工作教室",
    day: 19,
    hour: 15,
    minute: 45,
    description: "電気系学生が開発した工作キットを組み立てます！",
  },

  {
    name: "質問コーナー",
    day: 19,
    hour: 16,
    minute: 15,
    description: "Twitterで募集した質問にみつえれが答えるよ！",
  },

  {
    name: "学生制作物展示",
    day: 19,
    hour: 16,
    minute: 20,
    description: "学生が授業で作った制作物を紹介！",
  },

  {
    name: "研究室展示",
    day: 19,
    hour: 16,
    minute: 35,
    description: "部屋にいるだけで充電！？無線給電の最先端",
  },

  {
    name: "カードゲーム",
    day: 19,
    hour: 17,
    minute: 0,
    description: "論理回路をテーマにした自作のカードゲームをご紹介！",
  },

  {
    name: "研究室展示",
    day: 19,
    hour: 17,
    minute: 25,
    description: "次世代発電「プラズマ核融合」のメカニズム",
  },

  {
    name: "ゲームAI",
    day: 19,
    hour: 17,
    minute: 50,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },
  { name: "エンディング", day: 19, hour: 17, minute: 55, description: "" },

  { name: "オープニング", day: 20, hour: 12, minute: 30, description: "" },

  {
    name: "ゲームAI",
    day: 20,
    hour: 12,
    minute: 35,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },

  {
    name: "電子工作教室",
    day: 20,
    hour: 12,
    minute: 45,
    description: "電気系学生が開発した工作キットを組み立てます！",
  },

  {
    name: "研究室展示",
    day: 20,
    hour: 13,
    minute: 15,
    description: "制御の力で世界が変わる！無線給電",
  },

  {
    name: "舞台裏インタビュー",
    day: 20,
    hour: 14,
    minute: 35,
    description: "各コンテンツの制作過程を企画メンバーがご紹介！",
  },

  {
    name: "カードゲーム",
    day: 20,
    hour: 15,
    minute: 0,
    description: "論理回路をテーマにした自作のカードゲームをご紹介！",
  },

  {
    name: "ゲームAI",
    day: 20,
    hour: 15,
    minute: 25,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },

  {
    name: "研究室展示",
    day: 20,
    hour: 15,
    minute: 30,
    description: "地球を見てみよう、それもレーダーで。",
  },

  {
    name: "プログラミング教室",
    day: 20,
    hour: 16,
    minute: 0,
    description: "WEBで公開中のプログラミング教室にみつえれが挑戦！",
  },

  {
    name: "学生制作物展示",
    day: 20,
    hour: 16,
    minute: 20,
    description: "学生が授業で作った制作物を紹介！",
  },

  {
    name: "電子工作教室",
    day: 20,
    hour: 16,
    minute: 35,
    description: "電気系学生が開発した工作キットを組み立てます！",
  },

  {
    name: "ゲームAI",
    day: 20,
    hour: 16,
    minute: 40,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },

  {
    name: "質問コーナー",
    day: 20,
    hour: 16,
    minute: 55,
    description: "Twitterで募集した質問にみつえれが答えるよ！",
  },

  {
    name: "研究室展示",
    day: 20,
    hour: 17,
    minute: 5,
    description: "大きな装置で小さなマシンーMEMS研究紹介ー",
  },
 
  {
    name: "学生制作物展示",
    day: 20,
    hour: 17,
    minute: 35,
    description: "学生が授業で作った制作物を紹介！",
  },

  {
    name: "ゲームAI",
    day: 20,
    hour: 17,
    minute: 50,
    description: "だんだん強くなるゲームAIとみつえれが対戦！",
  },

  { name: "エンディング", day: 20, hour: 17, minute: 55, description: "" },
]

export default schedule
