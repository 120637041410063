import { Link } from "gatsby"
import PropTypes from "prop-types"
import { ReactComponent as BulbBlack } from "../images/bulb-black.svg"
import { ReactComponent as BulbWhite } from "../images/bulb-white.svg"
import MyDropdown from "./header/dropdown"
import menuContents from "./header/menuContents"
import Sidebar from "./header/sidebar"
import React from "react"

import * as stylePhone from "./header/header-smartphone.module.css"

const Header = ({ siteTitle }) => {
  return (
    <>
      <header className={stylePhone.pcheader}>
        <div>
          <div className="header-logo-container">
            <Link to="/">
              <div className="header-logo">
                <BulbBlack className="header-icon" height="45" width="36" />
                <h1>{siteTitle}</h1>
              </div>
            </Link>
          </div>
          <nav>
            {menuContents.map((content, index) => (
              <MyDropdown
                menuContent={content}
                index={index}
                key={index}
              ></MyDropdown>
            ))}
          </nav>
        </div>
      </header>
      <header className={stylePhone.phoneheader}>
        <Sidebar className={stylePhone.sidebar} />
        <div className={stylePhone.phoneheaderContainer}>
          <div className={stylePhone.phoneheaderLogoContainer}>
            <Link to="/">
              <div className={stylePhone.phoneheaderLogo}>
                <BulbWhite className="header-icon" height="45" width="36" />
              </div>
            </Link>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
