import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CommonEyecatch from "../components/common_eyecatch/index"
import LiveDetail from "../components/liveDetail/LiveDetail"

const CraftPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="配信企画"
        pathname="/live/"
        keywords={[
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="EEICオリジナルVTuberみつえれが「近未来体験2021」をご紹介！今すぐチェックしよう！"
      />
      <CommonEyecatch pageTitle="配信企画" pageSentences="EEICオリジナルVTuberみつえれが「近未来体験2021」をご紹介！" index={0} />
      <LiveDetail />
    </Layout>
  )
}

export const craftData = {
  
}

export default CraftPage
