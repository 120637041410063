import PropTypes from "prop-types"
import React from "react"
import commonBackground from "../../images/common-background.png"

const colorArray = ["rgb(255, 0, 255)", "#33CC00", "#0066FF", "#D1D656"]
const CommonEyecatch = ({
  pageTitle,
  pageSentences,
  image,
  index,
  className,
  children,
}) => (
  <section className={`project ${className || ``}`}>
    <img className="project-blur" src={image || commonBackground} alt="" />
    {children || (
      <div class="project-children">
        <h2>{pageTitle}</h2>
        <span
          class="project-border"
          style={{
            borderColor: colorArray[index],
          }}
        />
        <p
          className="sentences"
          dangerouslySetInnerHTML={{
            __html: pageSentences,
          }}
        />
      </div>
    )}
  </section>
)

CommonEyecatch.propTypes = {
  pageTitle: PropTypes.string,
  pageSentences: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
}

CommonEyecatch.defaultProps = {
  pageTitle: ``,
  pageSentences: ``,
}

export default CommonEyecatch
